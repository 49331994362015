import Swal from "sweetalert2";

Livewire.on('showSweetAlertInfo', showSweetAlertInfo);

function showSweetAlertInfo(data) {
    Swal.fire({
        title: data.title,
        icon: data.icon,
        text: data.text,
        showConfirmButton: true,
        confirmButtonText: data.button,
    }).then((_) => {
        if (data.link) {
            window.location.href = data.link
        }
    });
}
