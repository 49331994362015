import * as Sentry from "@sentry/browser";

console.log("USING SENTRY: " + import.meta.env.VITE_SENTRY_LARAVEL_DSN);

Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_LARAVEL_DSN,
    environment: import.meta.env.APP_ENV,

    tracesSampleRate: 0, //import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: import.meta.env.VITE_REPLAY_SESSIONS_SAMPLE_RATE,

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: import.meta.env.VITE_REPLAY_ERRORS_SAMPLE_RATE,

    integrations: [
        new Sentry.BrowserTracing({
            tracingOrigins: ["localhost", 'https://spine.mintellity.dev', import.meta.env.APP_URL, /^\//]
        }),
        new Sentry.Replay({})
    ],
});
