Livewire.on('initContainerDescription', _ => {
    $('#container_description').focus();
    $('#container_description').on('focusout', function () {
        Livewire.emit('editingDesciptionLoseFocus');
    });
});

Livewire.on('clickRepository', data => {
    $('#name').val(data[0]);
    $('#git_workspace').val(data[1]);
    $('#git_repository').val(data[2]);
});

Livewire.on('triggerHealthCheck', data => {
    Livewire.emit('performHealthChecks');
});
