import {Chart,registerables} from "chart.js";

Chart.register(...registerables);

const elem = $('#container_cpu_chart');

if(elem.length == 1) {
    const chart = new Chart(elem, {
        type: 'line',
        data: {
            labels: ["","","","","","","","","","","",""],
            datasets: [{
                label: '',
                data: [],
                fill: false,
                borderColor: 'rgb(75, 192, 192)',
                tension: 0.1
            }]
        },
        options: {
            animation: false,
            scales: {
                y: {
                    beginAtZero: true,
                    ticks: {
                        display: false
                    }
                }
            },
            responsive: true,
            plugins: {
                legend: {
                    position: 'none',
                },
                title: {
                    display: false,
                }
            }
        },
    });

    Livewire.on('containerCPUChartAddData', cpuData => {
        chart.data.datasets.at(0).data.push(cpuData)
        chart.data.datasets.at(0).cubicInterpolationMode = 'monotone'

        if(chart.data.datasets.at(0).data.length > 12) {
            chart.data.datasets.at(0).data.shift();
        }

        chart.update();
    })

}
