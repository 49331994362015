$('form').on('submit', function (e) {
    if ($(this).hasClass('ajax-form')) {
        e.preventDefault();

        let action = $(this).attr('action');
        let formData = new FormData(this);
        let method = $(this).attr('method');
        if (!method) {
            method = "post";
        }

        $('#submitLoadingSpinner').removeClass('hidden');
        $('#submitErrorTriangle').addClass('hidden');

        let errorTexts = $('[id^=validation_error_]');
        errorTexts.each(function(index, elem) {
            let errorText = $(elem);
            errorText.html(errorText.data('title'));
            errorText.removeClass('text-red-500');
        });

        $.ajax({
            url: action,
            method: method,
            data: formData,
            cache: false,
            contentType: false,
            processData: false,
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            error: function (result) {
                let data = result.responseJSON;
                let errors = data.errors;

                if (!jQuery.isEmptyObject(errors)) {
                    for (let field in errors) {
                        if (!errors.hasOwnProperty(field)) continue;
                        let message = errors[field][0];
                        //let inputField = $('#' + field);
                        let errorText = $('#validation_error_' + field)

                        errorText.html(message);
                        errorText.addClass('text-red-500');
                    }
                }

                $('#submitLoadingSpinner').addClass('hidden');
                $('#submitErrorTriangle').removeClass('hidden');
            },
            success: function (result) {
                if (result.hasOwnProperty('redirect')) {
                    document.location.href = result.redirect;
                }
            }
        });
    }
});
