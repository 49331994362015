import './sentry';
import './bootstrap';
import './destroy-confirmation';
import './ajax-form';
import './livewire-alerts';

import './charts/container_cpu_chart';
import './charts/container_memory_chart';
import './livewire';

import Alpine from 'alpinejs';

$('#dropdown_switch').on('click', function() {
    let target = $('#' + $(this).data('target'));

    if(target.hasClass('hidden')) {
        target.removeClass('hidden');
    } else {
        target.addClass('hidden');
    }
});

window.Alpine = Alpine;
Alpine.start();
